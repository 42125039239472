import { LOCALE_ID, NgModule } from '@angular/core';
import '../polyfills';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe, CommonModule, registerLocaleData} from '@angular/common';
import es from '@angular/common/locales/es';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor, JwtInterceptor } from 'src/app/helpers';
import { JwtModule } from '@auth0/angular-jwt';
// Common
import { OnlyNumbersDirective } from './directives/OnlyNumbers.directive';
import { AlertComponent } from './components/alert/alert.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { LoaderComponent } from './components/loader/loader.component';
// App
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PhonePipe } from './pipes';

registerLocaleData(es);

export function getToken() {
  return localStorage.getItem('TokenJwt');
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    JwtModule.forRoot({ // for JwtHelperService
      config: {
        tokenGetter: () => {
          return localStorage.getItem('TokenJwt');
        }
      }
    }),

  ],
  declarations: [
    AppComponent,
    OnlyNumbersDirective,
    PhonePipe,
    AlertComponent,
    ConfirmComponent,
    LoaderComponent,
  ],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: "es-ES" },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
