import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './helpers';
import { LandingComponent } from './views/landing/landing.component';
import { NotFoundComponent } from './views/notfound/notfound.component';

const routes: Routes = [{
  path: '', component: LandingComponent,
  }, {
  //   path: '', component: AuthComponent,
  //   children: [
  //     { path: 'login', component: LoginComponent },
  //     { path: 'forgot', component: ForgotComponent },
  // ]}, {
  //   path: 'app', canActivate: [AuthGuard],
  //     component: SystemComponent,
  //     data: { expectedRole: TipoUsuario.Administrador },
  //   children: [
  //       { path: '', component: HomeComponent },
  // ]}, {
      path: '**', component: NotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
