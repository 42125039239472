import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Alert, AlertType } from 'src/app/models';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<Alert>();
    private defaultId = `toast`;

    // enable subscribing to alerts observable
    onAlert(id = this.defaultId): Observable<Alert> {
        return this.subject.asObservable().pipe(filter(x => x && x.id === id));
    }

    // convenience methods
    success(title: string, message: string, timing: string, options?: any) {
        this.alert(new Alert({ ...options, icon: 'check-circle', type: AlertType.Success, title, message, timing }));
    }

    error(title: string, message: string, timing: string, options?: any) {
        this.alert(new Alert({ ...options, icon: 'exclamation-triangle', type: AlertType.Danger, title, message, timing }));
    }

    info(title: string, message: string, timing: string, options?: any) {
        this.alert(new Alert({ ...options, icon: 'bell', type: AlertType.Info, title, message, timing }));
    }

    warn(title: string, message: string, timing: string, options?: any) {
        this.alert(new Alert({ ...options, icon: 'exclamation-circle', type: AlertType.Warning, title, message, timing }));
    }

    // main alert method
    alert(alert: Alert) {
        alert.id = alert.id || this.defaultId;
        this.subject.next(alert);
    }

    // clear alerts
    clear(id = this.defaultId) {
        this.subject.next(new Alert({ id }));
    }

}
