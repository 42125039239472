<div *ngFor="let alert of alerts" class="{{cssClass(alert)}}" role="alert" data-animation="true" aria-live="assertive" aria-atomic="true">
  <div class="toast-header">
    <h6 class="mr-auto"><i class="fas fa-{{alert.icon}}"></i>&nbsp;{{alert.title}}</h6>
    <small class="text-muted">{{alert.timing}}</small>&nbsp;
    <button type="button" class="close" (click)="removeAlert(alert)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="toast-body text-white" [innerHTML]="alert.message"></div>
</div>
