import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, throwError, empty } from 'rxjs';
import { retry, catchError, delay } from 'rxjs/operators';
import { Resultado } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  ApiUrl = `${environment.apiUrl}`;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: `Bearer ${localStorage.getItem('TokenJwt')}`,
  });
  constructor(private httpClient: HttpClient) { }
  // Get Records List Paging
  getIndex<T>(url, httpParams): Observable<T> {
    return this.httpClient
      .get<T>(`${this.ApiUrl}/${url}`, { headers: this.httpHeaders, params: httpParams })
      .pipe(
        retry(1),
        delay(3000),
        catchError(this.handleError)
      );
  }
  // Get Record by Params
  getRecord<T>(url, httpParams): Observable<T> {
    return this.httpClient
      .get<T>(`${this.ApiUrl}/${url}`, { headers: this.httpHeaders, params: httpParams })
      .pipe(
        retry(1),
        delay(3000),
        catchError(this.handleError)
      );
  }
  // Get Records by Params
  getRecords<T>(url, httpParams): Observable<T> {
    return this.httpClient
      .get<T>(`${this.ApiUrl}/${url}`, { headers: this.httpHeaders, params: httpParams })
      .pipe(
        retry(1),
        delay(3000),
        catchError(this.handleError)
      );
  }
  // Add Record by Body/Form Data
  postRecord<T>(url, dataBody): Observable<T> {
    return this.httpClient
      .post<T>(`${this.ApiUrl}/${url}`, dataBody, { headers: this.httpHeaders })
      .pipe(
        retry(1),
        delay(3000),
        catchError(this.handleError)
      );
  }
  // Update Record by Body/Form Data & Params
  putRecord<T>(url, dataBody): Observable<T> {
    return this.httpClient
      .put<T>(`${this.ApiUrl}/${url}`, dataBody, { headers: this.httpHeaders })
      .pipe(
        retry(1),
        delay(3000),
        catchError(this.handleError)
      );
  }
  // Delete Record by Params
  delRecord<T>(url, httpParams): Observable<T> {
    return this.httpClient
      .delete<T>(`${this.ApiUrl}/${url}`, { headers: this.httpHeaders, params: httpParams })
      .pipe(
        retry(1),
        delay(3000),
        catchError(this.handleError)
      );
  }
  // Get Catalogs by Params
  async getData(url, httpParams): Promise<Resultado> {
    const result = await this.httpClient
      .get(`${this.ApiUrl}/${url}`, { headers: this.httpHeaders, params: httpParams })
      .pipe(
        retry(1),
        delay(3000),
        catchError(this.handleError)
      ).toPromise();
    return result as Resultado;
  }
  // Add Record by Body/Form Data
  async postData(url, dataBody): Promise<Resultado> {
    const result = await this.httpClient
      .post(`${this.ApiUrl}/${url}`, dataBody, { headers: this.httpHeaders })
      .pipe(
        retry(1),
        delay(3000),
        catchError(this.handleError)
      ).toPromise();
    return result as Resultado;
  }
  // Handle Error in Service
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `SysErr: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `SysError Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(`SysError: ${error}`);
    return throwError(errorMessage);
    //return empty();
  }
}
