  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top ">
    <div class="container d-flex align-items-center justify-content-between">
      <a href="/" class="logo"><img src="assets/img/ChantinH.png" alt="" class="img-fluid"></a>
        <nav id="navbar" class="navbar">
        <ul>
          <li><a class="nav-link scrollto active" href="#hero" onclick="var navbar = document.getElementById('navbar'); if (navbar.classList.contains('navbar-mobile')) { navbar.classList.remove('navbar-mobile'); var navbarToggle = document.getElementById('navToggle'); navbarToggle.classList.toggle('bi-list'); navbarToggle.classList.toggle('bi-x');}">Inicio</a></li>
          <li><a class="nav-link scrollto" href="#about" onclick="var navbar = document.getElementById('navbar'); if (navbar.classList.contains('navbar-mobile')) { navbar.classList.remove('navbar-mobile'); var navbarToggle = document.getElementById('navToggle'); navbarToggle.classList.toggle('bi-list'); navbarToggle.classList.toggle('bi-x');}">¿Que es?</a></li>
          <li><a class="nav-link scrollto" href="#services" onclick="var navbar = document.getElementById('navbar'); if (navbar.classList.contains('navbar-mobile')) { navbar.classList.remove('navbar-mobile'); var navbarToggle = document.getElementById('navToggle'); navbarToggle.classList.toggle('bi-list'); navbarToggle.classList.toggle('bi-x');}">¿Qué hace?</a></li>
          <!-- <li><a class="nav-link scrollto d-none" href="#portfolio" onclick="var navbar = document.getElementById('navbar'); if (navbar.classList.contains('navbar-mobile')) { navbar.classList.remove('navbar-mobile'); var navbarToggle = document.getElementById('navToggle'); navbarToggle.classList.toggle('bi-list'); navbarToggle.classList.toggle('bi-x');}">Clientes</a></li> -->
          <li><a class="nav-link scrollto" href="#pricing" onclick="var navbar = document.getElementById('navbar'); if (navbar.classList.contains('navbar-mobile')) { navbar.classList.remove('navbar-mobile'); var navbarToggle = document.getElementById('navToggle'); navbarToggle.classList.toggle('bi-list'); navbarToggle.classList.toggle('bi-x');}">Precios</a></li>
          <li><a class="nav-link scrollto" href="#contact" onclick="var navbar = document.getElementById('navbar'); if (navbar.classList.contains('navbar-mobile')) { navbar.classList.remove('navbar-mobile'); var navbarToggle = document.getElementById('navToggle'); navbarToggle.classList.toggle('bi-list'); navbarToggle.classList.toggle('bi-x');}">Contacto</a></li>
        </ul>
        <i id="navToggle" class="fas fa-bars mobile-nav-toggle" onclick="document.getElementById('navbar').classList.toggle('navbar-mobile'); this.classList.toggle('bi-list'); this.classList.toggle('bi-x');"></i>
      </nav><!-- .navbar -->
      <a href="#Demo" class="get-started-btn scrollto">Ver Demo</a>
    </div>
  </header><!-- End Header -->

  <!-- ======= Hero Section ======= -->
  <section id="hero">
    <div class="hero-container">
      <h3>¡Bienvenido! a <strong>CHANTIN</strong></h3>
      <h1>Administrador de Condominios</h1>
    </div>
  </section><!-- End Hero -->

  <main id="main">

    <!-- ======= About Section ======= -->
    <section id="about" class="about">
      <div class="container">

        <div class="section-title">
          <h2>¿Qué es?</h2>
          <h3>Aprenda más sobre <span>CHANTIN</span></h3>
          <p>Es un poderoso sistema que administra a sus residentes y condominios de forma eficiente y versátil.</p>
        </div>

        <div class="row content">
          <div class="col-lg-6">
            <p class="text-justify">
              Es un sistema innovador y completo, que sirve para planificar, organizar, dirigir y controlar las actividades
              y recursos con los que opera un condominio, edificio de oficinas y de departamentos, fraccionamiento, etcétera;
              a través de mecanismos comprobados en la aplicación de la Ley de Propiedad en Condominio del lugar en donde se opere.
            </p>
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0">
            <p class="text-justify">
              Al contar con un sistema especializado y dedicado exclusivamente a la administración de tu condominio, los problemas
              que pudieran presentarse se resolverán de manera oportuna y expedita, a diferencia de contar con un administrador físico
              que pueda dedicar algunas horas de la semana a la administración del condominio.
            </p>
          </div>
        </div>

        <div class="content pt-5 text-center">
          <a class="demo-btn" href="#contact">Pida una Demo gratis</a>
        </div>
      </div>
    </section><!-- End About Section -->

    <!-- ======= Services Section ======= -->
    <section id="services" class="services">
      <div class="container">

        <div class="section-title">
          <h2>¿Qué hace?</h2>
          <h3>Cuenta con diversas <span>Características</span></h3>
          <p>Para una mejor entendimiento de lo que hace, te presentamos algunas de sus funciones y características más relevantes:</p>
        </div>

        <div class="row">
          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box">
              <div class="icon"><i class="far fa-users"></i></div>
              <h4 class="title"><a href="">Unidad</a></h4>
              <p class="description">Fomenta la unión y buenas ralaciones entre los vecinos, generando así un bien común, por medio de redes de comunicacón internas.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box">
              <div class="icon"><i class="fas fa-hand-holding-usd"></i></div>
              <h4 class="title"><a href="">Ingresos y Egresos</a></h4>
              <p class="description">Control detallado de Pagos por Mantenimiento, Gastos de Limpieza, Obras Realizadas, Servicios Solicitados, etc.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box">
              <div class="icon"><i class="icon-vigilancia"></i></div>
              <h4 class="title"><a href="">Control Acceso</a></h4>
              <p class="description">Consta de varios elementos como Acceso para Visitas, Proveedores y Personal de Limpieza; Acceso Vehicular, Acceso Biométrico, etc.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box">
              <div class="icon"><i class="far fa-globe"></i>&nbsp;&nbsp;<i class="fad fa-mobile-alt"></i></div>
              <h4 class="title"><a href="">Doble Aplicación</a></h4>
              <p class="description">Sitio web y aplicación móvil en donde usted podrá consultar la información en cualquier momento, desde su computadora o celular.</p>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Services Section -->

    <!-- ======= Features Section ======= -->
    <section id="features" class="features">
      <div class="container">

        <div class="row">
          <div class="col-md-3 col-12 mt-4 mt-md-0">
            <div class="icon-box">
              <i class="icon-info icon-2x" style="color: #5578ff;"></i>
              <h3><a href="">Estados de Cuenta</a></h3>
            </div>
          </div>
          <div class="col-md-3 col-12 mt-4 mt-md-0">
            <div class="icon-box">
              <i class="fad fa-copy fa-2x" style="color: #e80368;"></i>
              <h3><a href="">Almacén de Documentos</a></h3>
            </div>
          </div>
          <div class="col-md-3 col-12 mt-4 mt-md-0">
            <div class="icon-box">
              <i class="fas fa-file-invoice fa-2x" style="color: #e361ff;"></i>
              <h3><a href="">Facturación Electrónica</a></h3>
            </div>
          </div>
          <div class="col-md-3 col-12 mt-4 mt-md-0">
            <div class="icon-box">
              <i class="fad fa-file-invoice-dollar fa-2x" style="color: #29cc61;"></i>
              <h3><a href="">Finanzas y Tesorería</a></h3>
            </div>
          </div>
          <div class="col-md-3 col-12 mt-4">
            <div class="icon-box">
              <i class="icon-asamblea1 icon-2x" style="color: #ffbb2c;"></i>
              <h3><a href="">Asambleas y Reuniones</a></h3>
            </div>
          </div>
          <div class="col-md-3 col-12 mt-4">
            <div class="icon-box">
              <i class="icon-mesadirectiva icon-2x" style="color: #11dbcf;"></i>
              <h3><a href="">Manejo de Mesa Directiva</a></h3>
            </div>
          </div>
          <div class="col-md-3 col-12 mt-4">
            <div class="icon-box">
              <i class="icon-atencion icon-2x" style="color: #b20969;"></i>
              <h3><a href="">Ventanilla de Atención</a></h3>
            </div>
          </div>
          <div class="col-md-3 col-12 mt-4">
            <div class="icon-box">
              <span class="fa-stack">
                <i class="far fa-bell fa-stack-1x"></i>
                <i class="fad fa-comment-alt fa-stack-2x" style="color: #47aeff;"></i>
              </span>
              <h3><a href="">Alertas y Notificaciones</a></h3>
            </div>
          </div>
          <div class="col-md-3 col-12 mt-4">
            <div class="icon-box">
              <i class="icon-asamblea2 icon-2x" style="color: #ffa76e;"></i>
              <h3><a href="">Comunicados y Avisos</a></h3>
            </div>
          </div>
          <div class="col-md-3 col-12 mt-4">
            <div class="icon-box">
              <i class="icon-pdomestico icon-2x" style="color: #4233ff;"></i>
              <h3><a href="">Control Personal Doméstico</a></h3>
            </div>
          </div>
          <div class="col-md-3 col-12 mt-4">
            <div class="icon-box">
              <i class="icon-areascomunes icon-2x" style="color: #b2904f;"></i>
              <h3><a href="">Agenda de Areas Comunes</a></h3>
            </div>
          </div>
          <div class="col-md-3 col-12 mt-4">
            <div class="icon-box">
              <i class="fad fa-calendar-alt" style="color: #ff5828;"></i>
              <h3><a href="">Agenda de Eventos</a></h3>
            </div>
          </div>
          <div class="col-md-3 col-12 mt-4">
            <div class="icon-box">
              <i class="fad fa-file-certificate fa-2x" style="color: #29cc61;"></i>
              <h3><a href="">Reconocimientos y Logros</a></h3>
            </div>
          </div>
          <div class="col-md-3 col-12 mt-4">
            <div class="icon-box">
              <i class="icon-encuesta icon-2x" style="color: #b2904f;"></i>
              <h3><a href="">Encuestas y Questionarios</a></h3>
            </div>
          </div>
          <div class="col-md-3 col-12 mt-4">
            <div class="icon-box">
              <i class="fad fa-id-card-alt fa-2x" style="color: #b20969;"></i>
              <h3><a href="">Directorio de Residentes</a></h3>
            </div>
          </div>
          <div class="col-md-3 col-12 mt-4">
            <div class="icon-box">
              <i class="fad fa-comments-alt fa-2x" style="color: #ff5828;"></i>
              <h3><a href="">Chat Interno</a></h3>
            </div>
          </div>
        </div>
      </div>
    </section><!-- End Features Section -->

    <!-- ======= Cta Section ======= -->
    <section id="cta" class="cta">
      <div class="container">
        <div class="text-center" data-aos="zoom-in">
          <h3>¿Necesita más información?</h3>
          <p>¿Quiere conconer mejor el sistema y verlo en operación, con todas sus características, para poder tomar una decisión?</p>
          <a class="cta-btn" href="#">Pida una Demo gratis</a>
        </div>

      </div>
    </section><!-- End Cta Section -->

    <!-- ======= Portfolio Section ======= -->
    <section id="portfolio" class="portfolio d-none">
      <div class="container">

        <div class="section-title">
          <h2>Clientes</h2>
          <h3>Algunos de nuestros <span>Clientes</span></h3>
          <p>Estos son algunos de los fraccionamientos, condominios y empresas admistradoras que se benfician con el sistema.</p>
        </div>
      </div>
    </section><!-- End Portfolio Section -->

    <!-- ======= Pricing Section ======= -->
    <section id="pricing" class="pricing">
      <div class="container">

        <div class="section-title">
          <h2>Precios</h2>
          <h3>Estos son nuestros <span>Paquetes</span></h3>
        </div>

        <div class="row">

          <div class="col-lg-4 col-md-6">
            <div class="box">
              <h1>Huactlacatl</h1>
              <h4><sup>$</sup>75 MXN</h4>
              <span>Mensual x residente desde 25 hasta 50</span>
              <ul class="d-none">
                <li>Toda la Funcionalidad</li>
                <li class="na">Hospedaje Basico</li>
                <li class="na">Dominio .com/.com.mx</li>
                <li class="na">Correos Administración</li>
                <li class="na">Certificado Básico SSL</li>
              </ul>
              <div class="btn-wrap">
                <a href="#" class="btn-buy">¡Pídalo Ahora!</a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
            <div class="box recommended">
              <span class="ribbon1">mejor oferta</span>
              <h1>Zan Cualtzin</h1>
              <h4><sup>$</sup>50 MXN</h4>
              <span>Mensual x residente desde 51 hasta 500</span>
              <ul class="d-none">
                <li>Toda la Funcionalidad</li>
                <li>Hospedaje Básico</li>
                <li>Dominio .com/.com.mx</li>
                <li>Certificado Básico SSL</li>
                <li class="na">Correos Administración</li>
              </ul>
              <div class="btn-wrap">
                <a href="#" class="btn-buy">¡Pídalo Ahora!</a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-4 mt-lg-0">
            <div class="box">
              <h1>Cuauhtic</h1>
              <h4><sup>$</sup>30 MXN</h4>
              <span>Mensual x residente desde 501</span>
              <ul class="d-none">
                <li>Toda la Funcionalidad</li>
                <li>Hospedaje Básico</li>
                <li>Dominio .com/.com.mx</li>
                <li>Correos Administración</li>
                <li>Certificado Básico SSL</li>
              </ul>
              <div class="btn-wrap">
                <a href="#" class="btn-buy">¡Pídalo Ahora!</a>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Pricing Section -->

    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
      <div class="container">

        <div class="section-title">
          <h2>Contacto</h2>
          <h3><span>Contáctanos</span></h3>
          <p>Tienes dudas, comentarios o quieres saber más sobre el sistema o nosotros, llena y envía el formulario y un asesor se comunicará contigo.</p>
        </div>

        <div class="row mt-5">

          <div class="col-lg-5">
            <div class="info">
              <div class="address">
                <i class="fas fa-map-marker-alt fa-2x"></i>
                <h4>Dirección:</h4>
                <p>Villa Toscana, Toluca, Edo Méx 50530</p>
              </div>

              <div class="email">
                <i class="fad fa-envelope fa-2x"></i>
                <h4>Email:</h4>
                <p>chantin@trinitysoftware.mx</p>
              </div>

              <div class="phone">
                <i class="fad fa-phone-office fa-2x"></i>
                <h4>LLámanos:</h4>
                <p>+52 722 541 9681</p>
              </div>

            </div>

          </div>

          <div class="col-lg-7 mt-5 mt-lg-0">

            <form role="form" class="php-email-form">
              <div class="row">
                <div class="col-md-4 form-group">
                  <input type="text" name="name" class="form-control" id="name" placeholder="Nombre" required>
                </div>
                <div class="col-md-4 form-group mt-3 mt-md-0">
                  <input type="email" class="form-control" name="email" id="email" placeholder="Email" required>
                </div>
                <div class="col-md-4 form-group mt-3 mt-md-0">
                  <input type="tel" class="form-control" name="phone" id="phone" placeholder="Teléfono" required>
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Asunto" required>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="5" placeholder="Mensaje" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Enviando</div>
                <div class="error-message"></div>
                <div class="sent-message">Tu mensaje ha sido enviado. ¡Gracias!</div>
              </div>
              <div class="text-center"><button type="submit"><i class="far fa-paper-plane"></i> Enviar Mensaje</button></div>
            </form>

          </div>

        </div>

      </div>
    </section><!-- End Contact Section -->

  </main><!-- End #main -->

  <!-- ======= Footer ======= -->
  <footer id="footer">

    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-md-4 col-sm-12 footer-contact">
            <img src="./assets/img/ChantinLetters.png" alt="" class="" width="45%">
            <p>
              Villa Toscana <br>
              Toluca, Edo Méx 50230<br>
              México <br><br>
              <strong>Teléfono:</strong> +52 722 541 9681<br>
              <strong>Email:</strong> chantin@trinitysoftware.mx<br>
            </p>
          </div>

          <div class="col-md-4 col-sm-12 footer-links">
            <h4>Enlaces útiles</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Inicio</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">¿Qué es?</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">¿Qué hace?</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Términos del Servicio</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Política de Privacidad</a></li>
            </ul>
          </div>

          <div class="col-md-4 col-sm-12 footer-newsletter">
            <h4>Únete a nuestro Boletín</h4>
            <p>Mantente al tanto de las Ofertas, Noticias, Eventos y nueva funcionalidad del sistema</p>
            <form action="" method="post">
              <input type="email" name="email" class="form-control"><input type="submit" value="Subscribir">
            </form>
          </div>

        </div>
      </div>
    </div>

    <div class="container d-md-flex py-4">

      <div class="me-md-auto text-center text-md-start">
        <div class="copyright">
          &copy; Copyright <strong><span class="text-azul"><a target="_blank" href="https://www.trinitysoftware.mx/">Trinity Software México</a></span></strong>. Todos los derechos reservados
        </div>
        <div class="credits">
          <!-- All the links in the footer should remain intact. -->
          <!-- You can delete the links only if you purchased the pro version. -->
          <!-- Licensing information: https://bootstrapmade.com/license/ -->
          Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
        </div>
      </div>
      <div class="social-links text-center text-md-right pt-3 pt-md-0">
        <a href="#" class="twitter"><i class="fab fa-twitter"></i></a>
        <a href="#" class="facebook"><i class="fab fa-facebook-f"></i></a>
        <a href="#" class="instagram"><i class="fab fa-instagram"></i></a>
        <a href="#" class="google-plus"><i class="fab fa-skype"></i></a>
        <a href="#" class="linkedin"><i class="fab fa-linkedin-in"></i></a>
      </div>
    </div>
  </footer><!-- End Footer -->

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="fad fa-arrow-up"></i></a>
