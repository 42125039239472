<div *ngIf="message" class="modal" tabindex="-1" role="dialog" style="display:block!important; z-index: 999999;">
  <div class="modal-dialog custom-alert" role="document">
      <div class="modal-content">
            <div *ngIf="message?.type == 'confirm'" class="modal-body">
              <div class="row">
                  <div class="col-md-12">
                      <p class="text-center confirm-message">{{message.text}}</p>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-12">
                      <p class="confirm-button">
                          <a class="mr-2" (click)="message.yesFn()">
                              <button class="btn btn-success">Aceptar</button>
                          </a>
                          <a (click)="message.noFn()">
                              <button class="btn btn-danger">Cancelar</button>
                          </a>
                      </p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
