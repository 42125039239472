<div class="blockUI" *ngIf="message !== ''">
  <div class="loader">
    <div class="spinner">
      <div style='left:30.72px;top:30.72px;animation-delay:0s'></div>
      <div style='left:53.76px;top:30.72px;animation-delay:0.125s'></div>
      <div style='left:76.8px;top:30.72px;animation-delay:0.25s'></div>
      <div style='left:76.8px;top:53.76px;animation-delay:0.375s'></div>
      <div style='left:76.8px;top:76.8px;animation-delay:0.5s'></div>
      <div style='left:53.76px;top:76.8px;animation-delay:0.625s'></div>
      <div style='left:30.72px;top:76.8px;animation-delay:0.75s'></div>
      <div style='left:30.72px;top:53.76px;animation-delay:0.875s'></div>
    </div>
    <span>{{message}}</span>
  </div>
</div>
