import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isUserLoggedIn()) {
      // NO es un usuario logueado
      //console.log('NO es un usuario válido');
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    // Obtener el Rol del Usuario
    // var user: Usuarios = <Usuarios><unknown>JSON.parse(localStorage.getItem('UserLogged'));
    // Verificar si la ruta es valida para el usuario
    if(!(state.url.indexOf('app') !== -1 && this.authService.isAdmin()))
    {
      // NO es un usuario permitido
      this.authService.logout();
      this.router.navigate(['/']);
      return false;
    }
    //console.log('Usuario permitido');
    return true;
  }
}
