import { ConfirmService } from './../../services/components/confirm.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  message: any; 
  constructor(
    private confirmService: ConfirmService
  ) { }

  ngOnInit(): any {
       /** 
        *   This function waits for a message from alert service, it gets 
        *   triggered when we call this from any other component 
        */  
        this.confirmService.getMessage().subscribe(message => {  
          this.message = message;  
      });  
  }
}
