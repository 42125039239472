import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiUser, Resultado } from 'src/app/models';
import { Usuarios } from 'src/app/entities';
import { RolUsuario } from 'src/app/enums';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  msgLogin: string;
  invalidLogin: boolean;
  currentUserSubject: BehaviorSubject<Usuarios>;
  currentUser: Observable<Usuarios>;
  user: Usuarios;
  url = `${environment.apiUrl}/${environment.apiAction.authUrl}`;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(
    private jwtHelper: JwtHelperService,
    private http: HttpClient,
  ) {
    let usr: Usuarios = JSON.parse('{}') as unknown as Usuarios;
    if (localStorage.getItem('UserLogged') != null && localStorage.getItem('UserLogged') !== '') {
      usr = (JSON.parse(localStorage.getItem('UserLogged')) as unknown as Usuarios);
    }
    this.currentUserSubject = new BehaviorSubject<Usuarios>(usr);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Usuarios {
    if (localStorage.getItem('UserLogged') !== null && localStorage.getItem('UserLogged') !== '') {
      return JSON.parse(localStorage.getItem('UserLogged')) as unknown as Usuarios;
    } else {
      this.logout();
    }
  }

  public isUserExpired(): boolean {
    return this.jwtHelper.isTokenExpired(localStorage.getItem('TokenJwt'));
  }

  public isUserLoggedIn(): boolean {
    return localStorage.getItem('UserLogged') !== null && localStorage.getItem('UserLogged') !== '' && !this.isUserExpired();
  }

  public isSuperAdmin(): boolean {
    let user: Usuarios = JSON.parse(localStorage.getItem('UserLogged')) as unknown as Usuarios;
    return user.idRol == RolUsuario.SuperAdmin;
  }

  public isAdmin(): boolean {
    let user: Usuarios = JSON.parse(localStorage.getItem('UserLogged')) as unknown as Usuarios;
    return user.idRol == RolUsuario.Administrador;
  }

  public isResidente(): boolean {
    let user: Usuarios = JSON.parse(localStorage.getItem('UserLogged')) as unknown as Usuarios;
    return user.idRol == RolUsuario.Residente;
  }

  public isVigilante(): boolean {
    let user: Usuarios = JSON.parse(localStorage.getItem('UserLogged')) as unknown as Usuarios;
    return user.idRol == RolUsuario.Vigilancia;
  }

  public isEmpleado(): boolean {
    let user: Usuarios = JSON.parse(localStorage.getItem('UserLogged')) as unknown as Usuarios;
    return user.idRol == RolUsuario.Empleado;
  }

  public login(model: ApiUser) {
    const body = JSON.stringify(model);
    return this.http.post<Resultado>(`${this.url}/LogIn`, body, this.httpOptions)
      .pipe(map(response => {
        // login successful if there's a jwt token in the response
        if (response == null && (response.data == null || response.data == '')) {
          return throwError('No se pudo obtener el usuario');
        }
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        this.user = (response.data.Usuario as unknown as Usuarios);
        // console.log('LogIn', this.user);
        localStorage.setItem('TokenJwt', response.data.Token);
        localStorage.setItem('UserLogged', JSON.stringify(response.data.Usuario));
        return this.user;
        // return this.user;
      }),catchError(error => {
        localStorage.setItem('TokenJwt', '');
        localStorage.setItem('UserLogged', '');
        return throwError(error);
      }));
  }

  public logout() {
    // remove user from local storage to log user out
    localStorage.setItem('TokenJwt', '');
    localStorage.setItem('UserLogged', '');
    console.log('LogOut');
    this.currentUserSubject.next(null);
  }

  public forgot(model: ApiUser) {
    const body = JSON.stringify({ Token: btoa(JSON.stringify(model)) });
    return this.http.post<ApiUser>(`${this.url}/Forgot`, body, this.httpOptions)
    .pipe(map(response => {
      // register successful
      if (response != null) {
        const respuesta = response as unknown as Resultado;
        return respuesta;
      }
      const resultado = new Resultado();
      resultado.exito = false;
      resultado.mensaje = 'No se puede recuperar la contraseña, intente nuevamente';
      return resultado;
    }),catchError(error => {
      console.log('Error forgot',error);
      return throwError(error);
    }));
  }

}
