import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  year = (new Date()).getFullYear;

  constructor(
    private loaderSrv: LoaderService,
  ) { }

  ngOnInit() {
    this.loaderSrv.hide();
  }

  toggleNav(e){
    console.log(e);
  }
}
