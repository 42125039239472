import { Component, OnInit, Input } from '@angular/core';
import { LoaderService } from 'src/app/services';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  message = '';

  constructor(
    public loaderService: LoaderService,
  ) { }

  ngOnInit(): any {
    this.loaderService.message.subscribe(msg => {
      this.message = msg;
    });
  }
}
