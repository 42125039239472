<router-outlet></router-outlet>
<!-- Preloader -->
<app-loader></app-loader>
<!-- Toasts / ALerts -->
<alert></alert>
<!-- Confirm Dialog -->
<app-confirm></app-confirm>
<!-- Template area -->
<div id="preloader"></div>
<a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
